import { Dropdown } from '@tymate/margaret';
import React, { forwardRef, useEffect, useRef } from 'react';
import styled, { css, useTheme } from 'styled-components';
import {
  PopoverItem,
  PopoverItemButton,
  PopoverMenu,
  PopoverItemPicture,
} from '../ui/popover';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { get, find } from 'lodash';

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 1rem;
  padding: ${({ theme }) => theme.spacing()};
  width: 100%;
  font-weight: 700;

  ${({ variant }) =>
    variant === 'home' &&
    css`
      font-weight: 400;
      background-color: #e73458;
      border-radius: 50px;
      width: 350px;

      svg {
        color: #fff !important;
      }
    `}

  ${({ variant }) =>
    variant === 'mobile' &&
    css`
      border-bottom: solid 1px ${({ theme }) => theme.separator};
    `}

  ${({ disabled, theme }) =>
    Boolean(disabled) &&
    css`
      background-color: ${theme.disabled};
      color: #6d6d6d;
    `}

  ${({ isBare }) =>
    isBare &&
    css`
      padding: 0;
    `}

  svg {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }

  ${({ variant }) =>
    variant === 'header' &&
    css`
      color: ${({ theme }) => theme.red} !important;
    `}
`;

const Value = styled.div`
  color: #fff;
  white-space: nowrap;

  ${({ variant }) =>
    variant === 'mobile' &&
    css`
      font-size: 20px;
      color: #000 !important;
      svg {
        color: #000;
      }
    `}

  ${({ isEmpty, theme, isScrolled }) => css`
    &,
    + svg {
      color: ${isEmpty ? theme.textLight : isScrolled ? '#000' : '#fff'};
    }
  `};

  ${({ disabled }) =>
    disabled &&
    css`
      &,
      + svg {
        color: #6d6d6d;
      }
    `}

  ${({ variant }) =>
    variant === 'home' &&
    css`
      color: #fff !important;
    `}

  ${({ variant }) =>
    variant === 'header' &&
    css`
      color: ${({ theme }) => theme.red} !important;
    `}
`;

const Select = forwardRef(
  ({
    placeholder,
    options,
    disabled,
    variant,
    wrapperStyle,
    value,
    selectedValue,
    isScrolled,
  }) => {
    const dropdownRef = useRef();
    const theme = useTheme();

    useEffect(() => {
      const selectedOption = find(options, option => option.value === value);

      if (!selectedOption) {
        return;
      }
    }, [options, value]);

    return (
      <Dropdown
        shouldCloseDropdownOnTriggerClick
        disabled={disabled}
        ref={dropdownRef}
        wrapperStyle={wrapperStyle}
        trigger={
          <Trigger disabled={disabled} variant={variant}>
            <Value
              disabled={disabled}
              isEmpty={!Boolean(selectedValue)}
              variant={variant}
              isScrolled={isScrolled}
            >
              {get(selectedValue, 'label', placeholder)}
            </Value>
            <MdKeyboardArrowDown size={18} color={theme.red} />
          </Trigger>
        }
      >
        <PopoverMenu variant={variant}>
          {options.map(({ url, label, picture }, key) => {
            return (
              <PopoverItem
                key={key}
                isSelected={Boolean(selectedValue?.url === url)}
              >
                <PopoverItemButton to={url}>
                  <PopoverItemPicture image={picture} />
                  {label}
                </PopoverItemButton>
              </PopoverItem>
            );
          })}
        </PopoverMenu>
      </Dropdown>
    );
  },
);

Select.defaultProps = {
  placeholder: 'Sélectionnez un parc',
  options: [],
  onUpdateSearch: () => {},
};

export default Select;
