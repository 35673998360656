import React, { useContext } from 'react';
import Select from 'components/Select';
import { useLocation } from 'react-use';
import { getLanguagePrefix } from 'utils';
import { PageContext } from 'contexts';

const ParkSelector = ({ parks, variant, isScrolled }) => {
  const { pathname } = useLocation();
  const { locale } = useContext(PageContext);

  const options = parks.map(({ location, slug }) => ({
    label: location.title,
    picture: location.picture.gatsbyImageData,
    url: getLanguagePrefix(locale, slug),
  }));

  const selectedValue = options.find(({ url }) => pathname?.includes(url));

  return (
    <Select
      isScrolled={isScrolled}
      options={options}
      variant={variant}
      selectedValue={selectedValue}
    />
  );
};

export default ParkSelector;
