import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/Seo';
import Layout from 'components/Layout';
import ParkSelector from 'components/ParkSelector';
import Video from 'components/blocks/Video';
import { media } from '@tymate/margaret';
import { HOMEPAGE_JSON_LD_SCHEMA } from 'constants';

const SelectWrapper = styled.div`
  position: absolute;
  top: 10%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  z-index: 100;

  ${media.tablet`
    top: 50%;
  `}
`;

const Section = styled.div`
  margin-bottom: 0 !important;
  margin-top: 0 !important;
`;

const Homepage = ({ data, pageContext }) => {
  const { allDatoCmsPark, allDatoCmsHomepage, datoCmsHomepage } = data;
  const parks = allDatoCmsPark?.edges
    ?.filter(({ node }) => node?.locale === 'fr')
    .map(item => {
      return item?.node;
    });

  return (
    <Layout variant="home" allDatoCmsPark={allDatoCmsPark}>
      <SEO
        title={datoCmsHomepage?.seo?.title}
        description={datoCmsHomepage?.seo?.description}
        jsonLdSchema={HOMEPAGE_JSON_LD_SCHEMA}
        jsonLdBreadcrumb={pageContext?.breadcrumbList}
        meta={[
          {
            name: 'robots',
            content: 'index, follow',
          },
        ]}
      />
      <Section>
        <Video
          blockVideoFullPage={allDatoCmsHomepage?.edges[0]?.node?.fullPageVideo}
          hasNoPlayButton
        />
      </Section>
      <SelectWrapper>
        <ParkSelector parks={parks} variant="home" />
      </SelectWrapper>
    </Layout>
  );
};

export const query = graphql`
  query ($locale: String!) {
    datoCmsHomepage {
      seo {
        title
        description
      }
    }
    allDatoCmsHomepage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          fullPageVideo {
            autoplay
            text
            mainTitle
            mainSvg {
              url
            }
            poster {
              url
              gatsbyImageData(
                placeholder: BLURRED
                forceBlurhash: false
                height: 1440
                width: 2560
              )
            }
            videoPhoto {
              format
              url
              video {
                streamingUrl
                mp4Url
              }
            }
          }
        }
      }
    }
    allDatoCmsPark(sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          name
          slug
          landings {
            id
            title
            sections {
              id
              title
            }
          }
          locale
          location {
            address
            id
            title
            picture {
              url
              gatsbyImageData(
                placeholder: BLURRED
                forceBlurhash: false
                height: 240
                width: 240
              )
            }
          }

          b2b {
            id
          }
          b2i {
            id
          }
          catering {
            id
          }
        }
      }
    }
  }
`;

export default Homepage;
